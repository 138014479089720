<template>
  <!--店铺装修-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--数据表格-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #30324a;">基本信息</span>
        </div>
        <el-row :gutter="15" style="margin-top:20px;">
          <el-col :span="4" style="text-align: center;">
            <el-upload
                :action="action"
                :headers="headers"
                accept=".jpg, .png, jpeg"
                :on-success="successUpload"
                :limit="1"
                :file-list="fileList"
                :class="{hide_box: upload_btn}"
                :on-change="change"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <div style="margin-top: 5px;">
              logo
            </div>
          </el-col>
          <el-col :span="8">
            <el-form
                :model="form"
                ref="form"
                label-width="100px">
              <el-form-item
                  label="后台名称:"
                  prop="">
                <el-input
                    v-model="form.admin_title"
                    placeholder="请输入后台名称"
                    clearable/>
              </el-form-item>
              <el-form-item
                  label="用户端名称:"
                  prop="">
                <el-input
                    v-model="form.users_title"
                    placeholder="请输入用户端名称"
                    clearable/>
              </el-form-item>
              <el-form-item
                  label="调度端名称:"
                  prop="">
                <el-input
                    v-model="form.dispatch_title"
                    placeholder="请输入调度端名称"
                    clearable/>
              </el-form-item>
              <el-form-item
                  label="司机端名称:"
                  prop="">
                <el-input
                    v-model="form.driver_title"
                    placeholder="请输入司机端名称"
                    clearable/>
              </el-form-item>
              <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm('form')">保存</el-button>
              </el-form-item>
            </el-form>
            <!--<img :src="img" alt="" style="width: 100px;height: 100px;">-->
            <!--<img src="@/assets/images/home/logo.png" alt="" style="width: 100px;height: 100px;">-->
          </el-col>
        </el-row>
      </el-card>

      <el-card shadow="never" style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 20px;font-weight: 700;color: #30324a;">后台管理</span>
        </div>
        <el-row :gutter="15" style="margin-top: 20px;">
          <el-col :span="4">
            <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">
              <div>
                <span style="font-size: 16px;font-weight: 500;color: #3f4157;">主题颜色修改</span>
              </div>
              <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">
                <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>
                <!--<input type="color" v-model='color' style="border: none;">-->
                <el-color-picker v-model="color" @change="changeColor"></el-color-picker>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">
              <div>
                <span style="font-size: 16px;font-weight: 500;color: #3f4157;">菜单栏背景颜色修改</span>
              </div>
              <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">
                <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>
                <!--<input type="color" v-model='color' style="border: none;">-->
                <el-color-picker v-model="color1" @change="changeColor1"></el-color-picker>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">
              <div>
                <span style="font-size: 16px;font-weight: 500;color: #3f4157;">二级背景颜色修改</span>
              </div>
              <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">
                <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>
                <!--<input type="color" v-model='color' style="border: none;">-->
                <el-color-picker v-model="color2" @change="changeColor2"></el-color-picker>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">
              <div>
                <span style="font-size: 16px;font-weight: 500;color: #3f4157;">顶部栏背景颜色修改</span>
              </div>
              <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">
                <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>
                <!--<input type="color" v-model='color' style="border: none;">-->
                <el-color-picker v-model="color3" @change="changeColor3"></el-color-picker>
              </div>
            </div>
          </el-col>
          <!--<el-col :span="4">-->
          <!--  <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
          <!--    <div>-->
          <!--      <span style="font-size: 16px;font-weight: 500;color: #3f4157;">列表页按钮颜色修改</span>-->
          <!--    </div>-->
          <!--    <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
          <!--      <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>-->
          <!--      <input type="color" v-model='color' style="border: none;">-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</el-col>-->
          <el-col :span="4">
            <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">
              <div>
                <span style="font-size: 16px;font-weight: 500;color: #3f4157;">自定义角色</span>
              </div>
              <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">
                <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>
                <span style="font-size: 24px;font-weight: 700;color: #202033;">{{roles_count}}</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="15" style="margin-top: 20px;">
          <!--<el-col :span="4">-->
          <!--  <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
          <!--    <div>-->
          <!--      <span style="font-size: 16px;font-weight: 500;color: #3f4157;">菜单栏内容修改</span>-->
          <!--    </div>-->
          <!--    <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
          <!--      <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
          <!--      <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</el-col>-->
          <!--<el-col :span="4">-->
          <!--  <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
          <!--    <div>-->
          <!--      <span style="font-size: 16px;font-weight: 500;color: #3f4157;">客户表单修改</span>-->
          <!--    </div>-->
          <!--    <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
          <!--      <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
          <!--      <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</el-col>-->
          <!--<el-col :span="4">-->
          <!--  <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
          <!--    <div>-->
          <!--      <span style="font-size: 16px;font-weight: 500;color: #3f4157;">客户表单修改</span>-->
          <!--    </div>-->
          <!--    <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
          <!--      <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
          <!--      <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</el-col>-->

        </el-row>
      </el-card>

      <!--<el-card shadow="never" style="margin-top: 20px;">-->
      <!--  <div style="display: flex;align-items: center;">-->
      <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
      <!--    <span style="font-size: 20px;font-weight: 700;color: #30324a;">用户端</span>-->
      <!--  </div>-->
      <!--  <el-row :gutter="15" style="margin-top: 20px;">-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">主题颜色修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>-->
      <!--          <input type="color" v-model='color' style="border: none;">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">按钮颜色修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>-->
      <!--          <input type="color" v-model='color' style="border: none;">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">服务项目修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">计价规则修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">法律条款修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--  </el-row>-->
      <!--</el-card>-->

      <!--<el-card shadow="never" style="margin-top: 20px;">-->
      <!--  <div style="display: flex;align-items: center;">-->
      <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
      <!--    <span style="font-size: 20px;font-weight: 700;color: #30324a;">调度端</span>-->
      <!--  </div>-->
      <!--  <el-row :gutter="15" style="margin-top: 20px;">-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">主题颜色修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>-->
      <!--          <input type="color" v-model='color' style="border: none;">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">按钮颜色修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>-->
      <!--          <input type="color" v-model='color' style="border: none;">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">创建订单内容修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">订单列表修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">订单详情修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">首页内容修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--  </el-row>-->
      <!--</el-card>-->

      <!--<el-card shadow="never" style="margin-top: 20px;">-->
      <!--  <div style="display: flex;align-items: center;">-->
      <!--    <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>-->
      <!--    <span style="font-size: 20px;font-weight: 700;color: #30324a;">司机端</span>-->
      <!--  </div>-->
      <!--  <el-row :gutter="15" style="margin-top: 20px;">-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">主题颜色修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>-->
      <!--          <input type="color" v-model='color' style="border: none;">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">按钮颜色修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">当前：</span>-->
      <!--          <input type="color" v-model='color' style="border: none;">-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">创建订单内容修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">订单列表修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">订单详情修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--    <el-col :span="4">-->
      <!--      <div style="background: #ffffff;border: 1px solid #ededf5;border-radius: 10px;text-align: center;padding-top: 10px;padding-bottom: 10px;">-->
      <!--        <div>-->
      <!--          <span style="font-size: 16px;font-weight: 500;color: #3f4157;">首页内容修改</span>-->
      <!--        </div>-->
      <!--        <div style="margin-top: 15px;display: flex;align-items: center; justify-content: center;">-->
      <!--          <span style="font-size: 14px;font-weight: 400;color: #666666;">数量：</span>-->
      <!--          <span style="font-size: 24px;font-weight: 700;color: #202033;">12</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </el-col>-->
      <!--  </el-row>-->
      <!--</el-card>-->

    </div>
  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'

import { mapGetters } from 'vuex'
import { put_shop_setup} from "@/api/system";

export default {
  // 组件生效
  components: {

  },

  computed: {
    // 主页标题, 移除国际化上面template中使用:home-title="setting.homeTitle"
    homeTitle () {
      return '平台首页'
    },
    ...mapGetters(['theme', 'admin'])
  },


  data(){
    return{
      // 表单数据
      // form: {},
      // // 表单验证规则
      // rules: {
      //   name: [
      //     {required: true, message: '请输入字典名称', trigger: 'blur'}
      //   ],
      //   sort: [
      //     {required: true, message: '请输入排序号', trigger: 'blur'}
      //   ]
      // },

      // 默认主题色
      color: this.$store.state.theme.color,
      // 菜单栏背景颜色
      color1: this.$store.state.theme.sideStyleBackground,
      // 二级背景颜色修改
      color2: this.$store.state.theme.erjisideStyleBackground,
      // 顶栏背景颜色修改
      color3: this.$store.state.theme.headerStyleBackground,
      // 自定义角色数量
      roles_count: 0,

      img: this.$store.state.theme.logo,


      // 上传图片
      // logo
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      logo:'',

      form:{
        admin_title:'',
        driver_title:'',
        dispatch_title:'',
        users_title:'',
      }


    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    console.log(this.$store)

    // this.getzhuangxiu();
  },

  methods:{
    //保存信息
    submitForm(){
      put_shop_setup(this.form).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.getzhuangxiu();
        }else{
          this.$message.error(res.msg);
        }
      })
    },

    // 获取店铺装修设置接口
    getzhuangxiu(){
      get_shop_setup().then(res => {
        console.log(res)
        //默认主题色
        this.color = res.data.theme_colors;
        this.$store.dispatch('theme/setColor', res.data.theme_colors).then(() => {

        }).catch((e) => {
          this.$message.error('主题加载失败')
        })

        // 菜单栏背景颜色
        this.color1 = res.data.menus_colors;
        this.$store.dispatch('theme/setsideStyleBackground', res.data.menus_colors).then(() => {

        }).catch((e) => {
          this.$message.error('主题加载失败')
        })

        // 二级背景颜色修改
        this.color2 = res.data.second_menus_colors;
        this.$store.dispatch('theme/seterjisideStyleBackground', res.data.second_menus_colors).then(() => {

        }).catch((e) => {
          this.$message.error('主题加载失败')
        })

        // 顶栏背景颜色修改
        this.color3 = res.data.header_colors;
        this.$store.dispatch('theme/setheaderStyleBackground', res.data.header_colors).then(() => {

        }).catch((e) => {
          this.$message.error('主题加载失败')
        })

        // 自定义角色数量
        this.roles_count = res.data.roles_count;

        //后台名称
        this.form.admin_title = res.data.admin_title;
        //用户端名称:
        this.form.users_title = res.data.users_title;
        //调度端名称
        this.form.dispatch_title = res.data.dispatch_title;
        //司机端
        this.form.driver_title = res.data.driver_title;

        // Logo
        if(res.data.logo != ''){
          let data = {
            url: res.data.logo
          };
          this.upload_btn = true;
          this.fileList = [];
          this.fileList.push(data);
          this.$store.dispatch('theme/setLog', res.data.logo).then(() => {

          }).catch((e) => {
            this.$message.error('主题加载失败')
          })
        }

      })
    },

    //logo
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.logo = file.data.urls;
      // 修改logo
      let data = {
        logo: this.logo
      }
      put_shop_setup(data).then(res => {
        if (res.code == 200){
          this.$store.dispatch('theme/setLog', this.logo).then(() => {
            this.$message.success(res.msg);
            // window.location.reload();
            this.getzhuangxiu();
          }).catch((e) => {
            console.error(e)
            this.$message.error('主题加载失败')
          })
          console.log(this.$store)
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    /* 切换主题色 */
    changeColor (value) {
      console.log(value)
      // 修改主题色
      let data = {
        theme_colors: this.color
      }
      put_shop_setup(data).then(res => {
        if(res.code == 200){
          const loading = this.$loading({ lock: true, background: 'transparent' })
          this.$store.dispatch('theme/setColor', value).then(() => {
            this.$message.success(res.msg);
            this.getzhuangxiu();
            loading.close()
          }).catch((e) => {
            console.error(e)
            loading.close()
            this.$message.error('主题加载失败')
          })
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    // 菜单栏背景颜色修改
    changeColor1(value){
      console.log(value)
      console.log(this.$store)
      // 修改菜单栏颜色
      let data = {
        menus_colos: this.color1
      }
      put_shop_setup(data).then(res => {
        if(res.code == 200){
          const loading = this.$loading({ lock: true, background: 'transparent' })
          this.$store.dispatch('theme/setsideStyleBackground', value).then(() => {
            this.$message.success(res.msg);
            this.getzhuangxiu();
            loading.close()
          }).catch((e) => {
            console.error(e)
            loading.close()
            this.$message.error('主题加载失败')
          })
        }else {
          this.$message.error(res.msg)
        }
      })


    },

    // 二级菜单背景颜色修改
    changeColor2(value){
      console.log(value)
      console.log(this.$store)
      // 修改二级菜单背景颜色
      let data = {
        second_menus_colos: this.color2
      }
      put_shop_setup(data).then(res => {
        if(res.code == 200){
          const loading = this.$loading({ lock: true, background: 'transparent' })
          this.$store.dispatch('theme/seterjisideStyleBackground', value).then(() => {
            this.$message.success(res.msg);
            this.getzhuangxiu();
            loading.close()
          }).catch((e) => {
            console.error(e)
            loading.close()
            this.$message.error('主题加载失败')
          })
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    // 顶部栏背景颜色修改
    changeColor3(value){
      // 顶栏背景颜色修改
      let data = {
        header_colors: this.color3
      }
      put_shop_setup(data).then(res => {
        if(res.code == 200){
          const loading = this.$loading({ lock: true, background: 'transparent' })
          this.$store.dispatch('theme/setheaderStyleBackground', value).then(() => {
            this.$message.success(res.msg);
            this.getzhuangxiu();
            loading.close()
          }).catch((e) => {
            console.error(e)
            loading.close()
            this.$message.error('主题加载失败')
          })
        }else {
          this.$message.error(res.msg)
        }
      })


    },



  }

}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

</style>
